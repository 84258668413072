<template>
  <div>
    <v-dialog v-model="showDialog" persistent fullscreen>
      <v-card :disabled="loading">
        <v-toolbar max-height="64">
          <span :class="[this.$vuetify.theme.dark? 'white--text' : '']" class="card-title ma-0">{{ $t('accounting.lang_enter_cash_book_entry') }}</span>
          <v-spacer/>
          <v-icon/>
        </v-toolbar>
        <v-divider/>
        <v-card-text class="pa-0 px-1">
          <v-container>
            <!-- TSE ALERT -->
            <v-row v-if="fiscalClient !== null">
              <v-col cols="12">
                <v-alert dark color="info"><b>Info: </b> {{ $t('accounting.lang_youCanOnlyCreateCashBookEntriesIfTseIsAccessible') }}
                </v-alert>
              </v-col>
            </v-row>

            <v-row>
              <v-card elevation="1" rounded width="100%">
                <v-card-text class="pa-1">
                  <v-row no-gutters>
                    <v-col cols="6" align-self="center">
                      {{ ' ' + $t('accounting.lang_closingBalancePeriod') }}: <b>{{ endCredit|currency }}</b>
                    </v-col>
                    <v-col cols="6" align="end">
                      <v-btn
                          elevation="0"
                          color="primary"
                          dark
                          outlined
                          @click="addItem()"
                      >
                        {{ $t("generic.lang_add") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider class="pa-0 ma-0"/>
                <v-card-text class="pa-1">
                  <v-data-table
                      class="responsive-table"
                      :items="items"
                      :headers="dataTableHeaders"
                      :loading="loading"
                  >
                    <template v-slot:loading>
                      <v-divider class="ma-0"/>
                    </template>
                    <template v-slot:body="{ items }">
                      <tbody>
                      <tr v-for="(item, index) in items" :key="index">

                        <td class="pa-1">
                          <v-row no-gutters align="center">
                            <v-col align-self="center">
                              <v-select outlined disabled
                                        :items="cashierIDs"
                                        v-model="item.cashierID"
                                        dense
                              ></v-select>
                            </v-col>
                          </v-row>
                        </td>
                        <td class="pa-1">
                          <v-row no-gutters align="center">
                            <v-col align-self="center">
                              <v-select outlined
                                        item-text="text" item-value="value"
                                        :items="[{text: $t('accounting.lang_income'),value: 1},{text: $t('accounting.lang_expense'),value: 0}]"
                                        v-model="item.type"
                                        required dense
                              ></v-select>
                            </v-col>
                          </v-row>
                        </td>
                        <td class="pa-1">
                          <v-row no-gutters align="center">
                            <v-col align-self="center">
                              <v-select outlined
                                        item-text="text"
                                        item-value="value"
                                        v-model="item.account"
                                        :rules="[v=>!!v||$t('generic.lang_requiredField')]"
                                        v-if="accounts && accounts != null"
                                        :items="accounts"
                                        dense>

                              </v-select>
                            </v-col>
                          </v-row>
                        </td>

                        <td class="pa-1">
                          <v-row no-gutters align="center">
                            <v-col align-self="center">
                              <v-currency-field
                                  :label="$t('generic.lang_total')"
                                  outlined
                                  required
                                  v-model="item.total"
                                  @focus="showTouchKeyboard($event,index)"
                                  :data-layout="KEYBOARD.KEYSETS.NUMERIC"
                                  :min="0"
                                  :auto-decimal-mode="true"
                                  :max="item.type===0?endCredit:null"
                                  local="de-DE"
                                  :allow-negative="false"
                                  :rules="[rules.checkTotalWithEndCredit,]"
                              ></v-currency-field>
                            </v-col>
                          </v-row>
                        </td>

                        <td class="pa-1">
                          <v-row no-gutters align="center">
                            <v-col align-self="center">
                              <v-select :items="taxes"
                                        item-text="name"
                                        item-value="tax"
                                        outlined
                                        dense
                                        v-model="item.tax"
                              ></v-select>
                            </v-col>
                          </v-row>
                        </td>

                        <td class="pa-1">
                          <v-row no-gutters align="center">
                            <v-col align-self="center">
                              <v-text-field outlined dense
                                            @focus="showTouchKeyboard"
                                            :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                            v-model="item.no"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </td>
                        <td class="pa-1">
                          <v-row no-gutters align="center">
                            <date-picker :rules="[v=>!!v||$t('generic.lang_requiredField')]" v-model="item.date"
                                         :hide-details="false"
                                         clearable dense/>

                          </v-row>
                        </td>

                        <td class="pa-1">
                          <v-textarea outlined dense
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      rows="2"
                                      v-model="item.freeText"
                          ></v-textarea>
                        </td>
                        <td class="pa-1">
                          <v-btn
                              fab
                              color="error"
                              class="ma-0"
                              dark
                              x-small
                              elevation="3"
                              @click="deleteItem(index)"
                          >
                            <v-icon :size="15">mdi-trash-can</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-card-text>
                <v-divider class="ma-0"/>
                <v-card-text class="pa-1">
                  <v-row no-gutters>
                    <v-col align="end">
                      <v-btn
                          elevation="0"
                          color="primary"
                          dark
                          outlined
                          @click="addItem()"
                      >
                        {{ $t("generic.lang_add") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider class="pa-0 ma-0 mb-2"/>
              </v-card>
            </v-row>

<!--            <v-row>-->
<!--              <v-col cols="12" sm="6">-->
<!--                <v-card class="rounded-lg mb-2" elevation="0" width="75%" color="#f0f0f0">-->
<!--                  <v-card-text>-->
<!--                    <v-row>-->
<!--                      <v-col cols="6">-->
<!--                        <span>{{ $t("accounting.lang_taxes") }}</span>-->
<!--                      </v-col>-->
<!--                      <v-col cols="6" align="right">-->
<!--                        <template v-for="(tax, index) in taxRate">-->
<!--                          <div :key="index">-->
<!--                            <span>{{ tax.rate }}% </span>-->
<!--                            <span>{{ tax.amount | currency }}</span>-->
<!--                          </div>-->
<!--                        </template>-->
<!--                        <v-divider v-if="taxRate.length > 1" class="ma-1"/>-->
<!--                        <span v-if="taxRate.length > 1">{{ totalTax | currency }}</span>-->
<!--                      </v-col>-->
<!--                    </v-row>-->
<!--                    <v-divider class="my-0"/>-->
<!--                    <v-row no-gutters>-->
<!--                      <v-col sm="12" md="12" lg="12" xl="12">-->
<!--                        <p class="ma-1 font-weight-light" style="color: #515c6f">-->
<!--                          {{ $t('erp.lang_cashEndTotal') }}-->
<!--                        </p>-->
<!--                        <p-->
<!--                            class="ma-1 fsize-3 font-weight-bold"-->
<!--                            style="color: #515c6f"-->
<!--                        >-->
<!--                          {{ total | currency }}-->
<!--                        </p>-->
<!--                        &lt;!&ndash;- <p class="ma-1" style="color:#515C6F;">CASH</p> &ndash;&gt;-->
<!--                      </v-col>-->
<!--                    </v-row>-->
<!--                  </v-card-text>-->
<!--                </v-card>-->
<!--              </v-col>-->
<!--            </v-row>-->
<!--            -->
          </v-container>
        </v-card-text>

        <v-toolbar bottom absolute class="mb-0" width="100%">
          <v-btn color="error" text @click="closeDialog">{{ $t('generic.lang_close') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1" :disabled=" loading || !valid"
              :loading="loading" text
              @click="checkTSE"
          >
            {{ $t('generic.lang_save') }}
          </v-btn>
          <v-btn
              color="blue darken-1" :disabled=" loading || !valid"
              :loading="loading" text
              @click="saveAndPrint"
          >
            {{ $t('generic.lang_saveAndPrint') }}
          </v-btn>
        </v-toolbar>
      </v-card>
      <!-- TSE ERROR DIALOG -->
      <v-dialog :value="tseErrorMessage.length > 0" persistent width="400">
        <v-card>
          <v-card-title>{{ $t('generic.lang_error') }}</v-card-title>
          <v-card-text v-text="tseErrorMessage"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="closeTSEErrorDialog">{{ $t('generic.lang_understand') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div id="onScreenKeyboardDivCashBookEntry" style="z-index: 299 !important;">
        <vue-touch-keyboard v-if="touchKeyboardCashBookEntry.visible" id="onScreenKeyboard"
                            :accept="hideTouchKeyboardCashBookEntry"
                            :cancel="hideTouchKeyboardCashBookEntry" :defaultKeySet="touchKeyboardCashBookEntry.keySet"
                            :input="touchKeyboardCashBookEntry.input" :layout="touchKeyboardCashBookEntry.layout"
                            :options="touchKeyboardCashBookEntry.options" class="internalWidthExpanded"/>
      </div>
    </v-dialog>

  </div>

</template>

<script>
import {ENDPOINTS, KEYBOARD} from "@/config";
import {Events} from "@/plugins/events";
import {mapGetters, mapState} from "vuex";
import DatePicker from "@/components/common/datepicker";
import DateTimePicker from "@/components/common/datetimepicker";
import {printDataFromPrinter} from "../../plugins/printing/printerController";
import {createCashBookPrintingData} from "../../plugins/printing/printOther";

export default {
  name: "BulkCashbookCreateEntryDialog",

  components: {
    DatePicker,
    DateTimePicker
  },

  props: {
    showDialog: Boolean,
    taxes: Array,
    accounts: Array,
    cashierIDs: Array,
    cashierID: Number,
    endCredit: Number,
  },

  watch: {
    showDialog(val) {
      if (!val) {
        this.createEntryType = "";
        this.createEntryTax = null;
        this.createEntryAccount = null;
        this.createEntryBelNo = "";
        this.createEntryFreetext = "";
        this.createEntryTotal = "";

        this.loading = false;

        this.createdEntryUUID = null;

        //DISABLE LISTENER
        Events.$off("eposDevice", this.eposDeviceCallback);
        Events.$off("GFE_OnReceive", this.GFE_OnReceiveCallback)
      }
    },
  },

  data() {
    return {
      loading: false,
      //
      KEYBOARD,
      touchKeyboardCashBookEntry: {
        visible: false,
        layout: "normal",
        input: null,
        options: {
          useKbEvents: false,
          preventClickEvent: false
        }
      },
      items: [],
      currentItem: null,// remembers the current item in the bulckchecktse inside the for loop for out of scope uses
      clearIntervalID: null,
      createEntryType: 0,
      createEntryTax: null,
      createEntryAccount: null,
      createEntryBelNo: "",
      createEntryBelDate: "",
      createEntryFreetext: "",
      createEntryTotal: "",
      cashierIDCreateVal: 1,
      lastItemFocus: 0,
      rules: {
        checkTotalWithEndCredit: (v) => {

          let val = (v + '').replace('.', '')
          val = Number(val.replace(',', '.'))

          if (isNaN(val)) {
            return this.$t('generic.lang_pleaseEnterValidNumber')
          }

          if (this.items[this.lastItemFocus] && this.items[this.lastItemFocus].type === 1) {
            return !!val || this.$t('generic.lang_requiredField');
          }
          if (this.total > Number(this.endCredit)) {
            return this.$t('generic.lang_shouldBeLessThanTheEndingBalance')
          }
          return !!val || this.$t('generic.lang_requiredField');
        },
      },
      createdEntryUUID: null,

      //TSE
      tseDevice: null,
      tseErrorMessage: "",
      tseErrorDialogButton: "",
    }
  },

  computed: {
    ...mapState([
      'api',
      'pos',
      'touchkeyboard',
        'user'
    ]),
    ...mapGetters({
      fiscalClient: 'tse/fiscalClient'
    }),
    ...mapState('printer', {
      printers: state => state.printers
    }),
    invoicePrinter() {
      return this.printers.find((printer) => {
        if (printer.cashierID.includes(this.api.auth.cashierID)) {
          if (printer.type === 1) {
            return true;
          }
        }
      });
    },
    frenchLayout() {
      return {
        _meta: {
          "tab": { key: "\t", text: "Tab", width: 60, classes: "control"},
          "shiftl": { keySet: "shifted", text: "Shift", width: 100, classes: "control"},
          "shiftr": { keySet: "shifted", text: "Shift", width: 100, classes: "control"},
          "caps": { keySet: "capsed", text: "Caps lock", width: 80, classes: "control"},
          "space": { key: " ", text: "Space", width: 180},
          "enter": { key: "\r\n", text: "Enter", width: 80, classes: "control"},
          "backspace": { func: "backspace", classes: "control backspace", width: 65},
          "accept": { func: "accept", text: "Close", classes: "control featured"},
          "next": { func: "next", text: "Next", classes: "control featured"}
        },
        default: [
          `² & é " ' ( - è _ ç à ) = {backspace}`,
          "{tab} a z e r t y u i o p ^ $ *",
          "{caps} q s d f g h j k l m ù {enter}",
          "{shiftl} w x c v b n , ; : ! {shiftr}",
          "{next} {space} , {accept}"
        ],
        shifted: [
          " 1 2 3 4 5 6 7 8 9 0 ° + {backspace}",
          "{tab} A Z E R T Y U I O P ¨ £ µ",
          "{caps} Q S D F G H J K L M % {enter}",
          "{shiftl} W X C V B N ? . / § {shiftr}",
          "{next} {space} , {accept}"
        ],
        capsed: [
          "² 1 2 3 4 5 6 7 8 9 0 ° + {backspace}",
          "{tab} A Z E R T Y U I O P ¨ £ µ",
          "{caps} Q S D F G H J K L M % {enter}",
          "{shiftl} W X C V B N ? . / § {shiftr}",
          "{next} {space} , {accept}"
        ]		
      };
    },
    germanLayout() {
      return {
        _meta: {
          "tab": { key: "\t", text: "Tab", width: 60, classes: "control"},
          "shiftl": { keySet: "shifted", text: "Shift", width: 100, classes: "control"},
          "shiftr": { keySet: "shifted", text: "Shift", width: 100, classes: "control"},
          "caps": { keySet: "capsed", text: "Caps lock", width: 80, classes: "control"},
          "space": { key: " ", text: "Space", width: 180},
          "enter": { key: "\r\n", text: "Enter", width: 80, classes: "control"},
          "backspace": { func: "backspace", classes: "control backspace", width: 65},
          "accept": { func: "accept", text: "Close", classes: "control featured"},
          "next": { func: "next", text: "Next", classes: "control featured"}
        },
        default: [
          "^ 1 2 3 4 5 6 7 8 9 0 ß ´ {backspace}",
          "{tab} q w e r t z u i o p ü + #",
          "{caps} a s d f g h j k l ö ä {enter}",
          "{shiftl} y x c v b n m , . - {shiftr}",
          "{next} {space} , {accept}"
        ],
        shifted: [
          '° ! " § $ % & / ( ) = ? ` {backspace}',
          "{tab} Q W E R T Z U I O P Ü * '",
          "{caps} A S D F G H J K L Ö Ä {enter}",
          "{shiftl} Y X C V B N M ; : _ {shiftr}",
          "{next} {space} , {accept}"
        ],
        capsed: [
          '^ ! " § $ % & / ( ) = ? ´ {backspace}',
          "{tab} Q W E R T Z U I O P Ü * '",
          "{caps} A S D F G H J K L Ö Ä {enter}",
          "{shiftl} Y X C V B N M ; : - {shiftr}",
          "{next} {space} , {accept}"
        ]		
      };
    },

    dataTableHeaders() {
      return [
        {
          text: this.$t('generic.lang_cashierID'),
          value: "cashierID",
          width: 60,
        },
        {
          text: this.$t('accounting.lang_invoiceType'),
          value: "type",
          width: 130,
        },
        {
          text: this.$t('accounting.langAccountingAccount'),
          value: "account",
          width: 100,
        },
        {
          text: this.$t("generic.lang_total"),
          value: "total",
          width: 130,
        },
        {
          text: this.$t('accounting.lang_taxes'),
          value: "tax",
          width: 130,
        },
        {
          text: this.$t('accounting.lang_invoiceNo'),
          value: "no",
          width: 130,
        },
        {
          text: this.$t('accounting.lang_receiptDate'),
          value: "date",
          width: 130,
        },
        {
          text: this.$t('generic.lang_Freitext'),
          value: "freeText",
          width: 130,
        },
        {
          text: "",
          width: 50,
          value: "crud",
        },
      ]
    },
    validItems() {
      return this.items.filter(item => /*(!isNaN(item.tax) && Number(item.tax) !== 0) && */(!isNaN(item.total) && Number(item.total) > 0))
    },
    valid() {
      let validItems = this.validItems.filter(item => (item.account > 0 && item.date && Number(item.total) > 0));
      return (validItems && validItems.length === this.items.length);
    },
    taxRate() {
      let tax = [];
      let itemCounter = 0
      if (this.validItems && this.validItems.length > 0) {
        this.validItems.forEach((item) => {
          ++itemCounter;
          let existedTax = tax.indexOf(tax.find(el => {
            return (Number(el.rate) === Number(item.tax))
          }))
          let subTotal = Number(item.total);
          subTotal = (Number(item.tax) / 100) * subTotal;
          if (existedTax >= 0) {
            tax[existedTax].amount += subTotal;
          } else {
            tax.push({
              rate: Number(item.tax),
              amount: subTotal
            });
          }
        });
        if (itemCounter === 0) {
          tax = [
            {
              rate: 0,
              amount: 0,
            }
          ]
        }
      } else {
        tax = [
          {
            rate: 0,
            amount: 0,
          }
        ]
      }
      return tax;
    },

    totalTax() {
      let total = 0;
      this.taxRate.forEach((tax) => {
        total += tax.amount;
      });
      return total;
    },
    total() {
      let total = 0;

      this.items.forEach(item => {
        if (Number(item.total))
          total += Number(item.total);
      })

      return total;
    },
  },

  mounted() {
    Events.$on("eposDevice", this.eposDeviceCallback);
    Events.$on("GFE_OnReceive", this.GFE_OnReceiveCallback);
    //LISTEN FOR OUTSIDE CLICK
    document.addEventListener('click', this.dismissOnScreenKeyboardCashBookEntry);

    this.cashierIDCreateVal = this.api.auth.cashierID;
    this.addItem();
  },
  destroyed() {
    clearInterval(this.clearIntervalID);
    document.removeEventListener('click', this.dismissOnScreenKeyboardCashBookEntry);
  },
  beforeDestroy() {
    Events.$off("eposDevice", this.eposDeviceCallback);
    Events.$off("GFE_OnReceive", this.GFE_OnReceiveCallback)
  },

  methods: {
    saveAndPrint(){
      this.loading = true;
      let printData = createCashBookPrintingData(this.validItems,this.user.userID);
      printDataFromPrinter(this.invoicePrinter,printData).then(()=>{

      }).catch((err)=>{
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_printReceiptError'),
          color: "error"
        });
      }).finally(()=>{
        this.loading = false;
        this.checkTSE()
      })
    },
    addItem() {
      let now = new Date();
      this.items.push({
        cashierID: this.cashierID,
        type: 0,
        tax: null,
        account: null,
        no: null,
        date: now.toISOString().substr(0, 10),
        freeText: null,
        total: 0.0,
      })
    },
    clearItem(item) {
      item.cashierID = null;
      item.type = null;
      item.tax = null;
      item.account = null;
      item.no = null;
      item.date = now.toISOString().substr(0, 10);
      item.freeText = null;
      item.total = 0.0;
    },
    deleteItem(index) {
      this.items.splice(index, 1);
    },
    checkNegativity(val) {
      let newVal = val.toString();
      newVal = newVal.replace(",", "");
      newVal = newVal.replace(".", "");
      if (Number(newVal) < 0 || isNaN(newVal)) item.total = 0;

    },
    showTouchKeyboard(e, index = null) {
      if (index >= 0) {
        this.lastItemFocus = index;
      }
      if (!this.touchkeyboard.settings.enabled)
        return;

      this.touchKeyboardCashBookEntry.input = e.target;
      if (!(e.root && e.root.classList.contains('ql-editor'))) {
        this.touchKeyboardCashBookEntry.layout = e.target.dataset.layout;
      } else {
        this.touchKeyboardCashBookEntry.layout = "normal";
      }

      if (this.touchKeyboardCashBookEntry.layout === "normal") {
        if(store.getters['settings/getSettingValue']('geoloc_systemcountry') === "fr") {
          this.touchKeyboardCashBookEntry.layout = this.frenchLayout;
        } else if(store.getters['settings/getSettingValue']('geoloc_systemcountry') === "de") {
          this.touchKeyboardCashBookEntry.layout = this.germanLayout;
        }
      }

      if (!this.touchKeyboardCashBookEntry.visible) {
        //TIMEOUT BECAUSE OUR OUTSIDE CLICK LISTENER IS CLOSING THE WINDOW INSTEAD
        this.clearIntervalID = setTimeout(() => {
          this.touchKeyboardCashBookEntry.visible = true;
        }, 150);
      }
    },
    hideTouchKeyboardCashBookEntry() {
      if (this.touchKeyboardCashBookEntry.visible) {
        this.touchKeyboardCashBookEntry.visible = false;
      }
    },
    dismissOnScreenKeyboardCashBookEntry: function (e) {
      if (this.touchkeyboard.settings.enabled && document.getElementById('onScreenKeyboardDivCashBookEntry')) {
        if (!document.getElementById('onScreenKeyboardDivCashBookEntry').contains(e.target)) {
          if (e.target.tagName !== "INPUT" && e.target.tagName !== "TEXTAREA") {
            this.hideTouchKeyboardCashBookEntry();
          }
        }
      }
      //this.sleep(1000);
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    async createEntries(withTSE = true) {
      //this.sleep(1000);
      //let newEntriesPomises = [];
      this.loading = true;
      for (let i = 0; i < this.validItems.length; i++) {
        //newEntriesPomises.push(this.createEntry(withTSE, this.validItems[i]))
        await this.createEntry(withTSE, this.validItems[i]);
      }

      this.loading = false;
      this.exitDialog();

      // Promise.allSettled(newEntriesPomises).then(res=>{
      //   console.log(res)
      //   this.closeDialog();
      // }).catch(err => {
      //   console.log(err)
      // }).finally(() => {
      //   this.loading = false;
      // })
    },
    sleep(milliseconds) {
      const date = Date.now();
      let currentDate = null;
      do {
        currentDate = Date.now();
      } while (currentDate - date < milliseconds);
    },
    async checkTSE() {

      //this.sleep(1000);
      //CHECK IF TSE CLIENT IS SETUP
      if (this.fiscalClient === null) {
        await this.createEntries(false);
        return;
      }

      if (this.fiscalClient.device.type === null) {
        this.fiscalClient.device.type = "epsonTSE";
      }
      // IF WE USE CLOUD TSE THE ENTRY IS BEEING FISCALIZED IN BACKEND
      if (this.fiscalClient.device.type !== "epsonTSE") {
        await this.createEntries(false);
        return;
      }


      //CHECK IF TSE DEVICE ALREADY ADDED
      let tsePrinters = this.$eposClass.getTSEPrinters();

      if (!tsePrinters.hasOwnProperty(this.fiscalClient.id)) {
        //ADD NEW TSE PRINTER
        this.$eposClass.addTSEPrinter(new this.$epson.ePOSDevice(), {
          id: this.fiscalClient.id,
          TSEProxyIPAdress: this.fiscalClient.device.TSEProxyIPAdress,
          ip: this.fiscalClient.device.ip,
          port: this.fiscalClient.device.port,
          deviceID: this.fiscalClient.device.deviceID,
          adminID: 'Administrator',
          clientID: this.fiscalClient.clientID
        }, false, false, (this.fiscalClient.device.useTSEProxy === 1));

        this.tseDevice = this.$eposClass.getTSEPrinter(this.fiscalClient.id);
      } else {
        this.tseDevice = this.$eposClass.getTSEPrinter(this.fiscalClient.id);

        if (this.tseDevice?.tseReady) {
          //CREATE ENTRY
          await this.createEntries(true);
        } else {
          await this.createEntries(false);
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_tseIsNotOperational'),
            color: "warning"
          });
          // if (this.tseDevice.connected) {
          //   //TSE WAS ADDED BUT ISNT READY YET
          //   this.loading = false;
          //   this.tseErrorMessage = "TSE ist nicht einsatzbereit...probiere es erneut.";
          //   await this.createEntries(false);
          // } else {
          //   //TRY TO RE-CONNECT!!!
          //   this.loading = false;
          //
          //   this.tseDevice.connect();
          // }
        }
      }
    },
    createEntry(withTSE = true, entry) {
      //this.sleep(1000);
      return new Promise((resolve, reject) => {

        this.axios.post(ENDPOINTS.ACCOUNTING.CASHBOOK.CREATE, {
          cashBookCashierIDEntry: entry.cashierID,
          buchungsBelegArt: entry.type,
          buchungsSatzSteuer: entry.tax,
          cashBookKontoNo: entry.account,
          cashBookBelegRr: entry.no,
          Belegdatum: entry.date,
          cashBookNotes: entry.freeText,
          cashBookTotal: entry.total,
          shouldCommited: (withTSE ? 1 : 0),
        }).then(async (res) => {
          if (res.data.status === 'SUCCESS') {
            this.createdEntryUUID = res.data.entryUUID;
            this.currentItem = entry;
            if (withTSE)
              await this.startTransaction(entry).then(() => {
                resolve();
              });
            else
              await this.finishCreating().then(() => {
                resolve();
              });


          } else {
            reject();
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_errorOccurred'),
              color: "error"
            });
          }
        }).catch(() => {
          reject()
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        });

      })
    },
    async startTransaction(entry) {
      //this.sleep(1000);
      this.tseDevice?.GFE_StartTransaction(this.fiscalClient.clientID);

      Events.$once("GFE_OnReceive", async (payload) => {
        if (payload.result.function === "StartTransaction") {
          if (payload.result.result === "EXECUTION_OK") {
            await this.finishTransaction(payload.result, entry);
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_errorOccurred'),
              color: "error"
            });
            this.tseErrorMessage = this.$t('generic.lang_couldNotCompleteSigning') + ": " + payload.result.result;
            this.tseErrorDialogButton = "close";
          }
        }
      });
    },
    async finishTransaction(startTransactionResult, entry) {
      //this.sleep(1000);
      //CREATE FAKE ITEM TO USE EXISTING FUNCTION
      let items = [{
        isVoid: false,
        sellPrice: parseFloat(entry.total).toFixed(2) * (entry.type === 0 ? -1 : 1),
        taxValue: parseFloat(entry.tax),
        amount: 1,
      }];

      this.tseDevice.finishInvoiceTransaction(this.fiscalClient.clientID, startTransactionResult.output, items, 1, []);

      Events.$once("GFE_OnReceive", async (payload) => {
        if (payload.result.function === "FinishTransaction") {
          if (payload.result.result === "EXECUTION_OK") {
            await this.finishCreating(startTransactionResult, payload.result);
          } else {
            this.tseErrorMessage = this.$t('generic.lang_couldNotCompleteSigning') + ": " + payload.result.result;
            this.tseErrorDialogButton = "close";
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_errorOccurred'),
              color: "error"
            });
          }
        }
      });
    },
    async finishCreating(startTransactionResult = null, finishTransactionResult = null,) {
      //this.sleep(1000);
      //SAVE FISCAL DATA INTO DATABASE
      if (startTransactionResult !== null && finishTransactionResult !== null) {
        let payload = {
          fiscalDevice: {
            id: this.fiscalClient.device.id,
            deviceID: this.fiscalClient.device.deviceID
          },
          fiscalClient: {
            id: this.fiscalClient.id,
            clientID: this.fiscalClient.clientID
          },
          tse: {
            serialNumber: this.tseDevice.storageInfo.tseInformation.serialNumber,
          },
          transaction: {
            processType: "Kassenbeleg-V1",
            startTime: startTransactionResult.output.logTime,
            stopTime: finishTransactionResult.output.logTime,
            transactionNumber: startTransactionResult.output.transactionNumber,
            signatureCounter: finishTransactionResult.output.signatureCounter,
            signature: finishTransactionResult.output.signature
          }
        };

        //SAVE FISCAL DATA INTO DATABASE
        await this.axios.post(ENDPOINTS.ACCOUNTING.CASHBOOK.UPDATETSEDATA, {
          entryUUID: this.createdEntryUUID,
          tsePayload: payload
        })
      }
    },
    exitDialog() {
      Events.$emit("showSnackbar", {
        message: this.$t('generic.lang_cashBookEntryCreated'),
        color: "success"
      });
      this.$emit("entryCreated");
    },
    closeTSEErrorDialog() {
      if (this.tseErrorDialogButton === "close") {
        this.exitDialog();
      } else {
        this.tseErrorMessage = "";
      }
    },

    // -----------------------------------------------
    // ------------------ GFE / TSE ------------------
    // ------------------ CALLBACKS ------------------
    // -----------------------------------------------
    eposDeviceCallback(payload) {
      // IMPORTANT
      if (!this.showDialog)
        return;

      if (payload.action === "createDeviceCallback.error") {
        this.tseErrorMessage = `${this.$t('generic.lang_setupOfTheTseCouldNotBeStarted')}<br>(${payload.code})`;
      }

      if (payload.action === "connectCallback.error") {
        //CONNECTION ERROR
        this.tseErrorMessage = this.$t('generic.lang_connectionToTseCouldNotBeEstablishedCheckIp')
      }

      if (payload.action === "disconnect") {
        this.tseDevice = null;
      }
    },
    GFE_OnReceiveCallback(payload) {
      // IMPORTANT
      if (!this.showDialog)
        return;

      if (payload.result.function !== "StartTransaction") {
        if (payload.result.function === "GetStorageInfo") {
          //TSE FINALLY FINISHED CONNECTING
          //START INSERTING CASHBOOK ENTRY
          this.createEntry(true, this.currentItem);
        } else {
          if (payload.result.result !== "EXECUTION_OK") {
            //OTHER ERROR OCCURED -> ABORTING
            this.tseErrorMessage = `${this.$t('generic.lang_errorOccurred')}<br>(${payload.result.result})`;
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_errorOccurred'),
              color: "error"
            });
            this.exitDialog();
          }
        }
      }
    }
  }
}
</script>